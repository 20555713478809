import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take, tap, switchMap} from 'rxjs/operators';
import { AccountService } from '../_services/account.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private accountService: AccountService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.accountService.initDone.pipe(
			filter(done => done === true),
            switchMap(() => {
                return this.accountService.userValue.pipe(
			        map((user) => {
				        if (user) {
                            if(route.data['role'] && !user?.roles.includes(route.data['role'])) {
                                this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
                                return false;
                            }
                            return true;
				        } else {
					        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
					        return false;
				        }
			        })
                );
            }));
        }
}