import { NgModule, isDevMode, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER, ErrorHandler} from '@angular/core';
import { Router } from "@angular/router";
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Drivers } from '@ionic/storage';
import { FullCalendarModule } from '@fullcalendar/angular';
import { PopupService } from 'survey-angular-ui';
import { IonicStorageModule } from '@ionic/storage-angular';
import { StorageService } from './_services/storage.service';
import { MatPasswordStrengthModule } from "@angular-material-extensions/password-strength";
import { LandingComponent } from './landing/landing.component';
import { PwaComponent } from './pwa/pwa.component';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import * as Sentry from "@sentry/angular-ivy";
import { PwaService } from './_services/pwa.service';



@NgModule({
  declarations: [AppComponent, LandingComponent, PwaComponent],
  imports: [
    BrowserModule,  
    HttpClientModule, 
    IonicModule.forRoot(), 
    MatPasswordStrengthModule.forRoot(), 
    AppRoutingModule, 
    FullCalendarModule,
    FontAwesomeModule,
    BrowserAnimationsModule, 
    IonicStorageModule.forRoot({ name: '__mydb', driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]}),
    ServiceWorkerModule.register('ngsw-worker.js', {
  enabled: !isDevMode(),
  // Register the ServiceWorker as soon as the application is stable
  // or after 30 seconds (whichever comes first).
  registrationStrategy: 'registerImmediately'
})],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, StorageService, 
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }, 
    {provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    PopupService,
    PwaService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
  }
}
