import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, RouterState } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from '../../environments/environment';

declare const gtag: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor(
    private router: Router,
    private titleService: Title,
    @Inject(DOCUMENT) private document: Document
  ) { 
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const title = this.getTitle(this.router.routerState.root);
        this.titleService.setTitle(title);
        this.sendPageView(title, event.urlAfterRedirects);
      }
    });
  }

  init() {
    if (document.getElementById('ga-script')) return;
    const script = document.createElement('script');
    script.id = 'ga-script';
    script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalytics}`;
    script.async = true;
    document.head.appendChild(script);

    const gtagScript = document.createElement('script');
    gtagScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${environment.googleAnalytics}', {'send_page_view': false});
    `;
    document.head.appendChild(gtagScript);
  }

  sendPageView(title: string, path: string) {
    if (typeof gtag === 'function') {
      console.log('gtag function is available');
      gtag('event', 'page_view', {
        page_title: title,
        page_path: path,
        page_location: this.document.location.href
      });
    } else {
      console.error('gtag function is not available');
    }
  }

  getTitle(route: ActivatedRoute): string {
    let title = '';
    while (route) {
      if (route.snapshot.data && route.snapshot.data['title']) {
        title = route.snapshot.data['title'];
      } 
      if (route.snapshot.paramMap.get('slug'))  {
        title += ` - ${route.snapshot.paramMap.get('slug')}`;
      }
      if (route.snapshot.paramMap.get('id')) {
        title += ` - ${route.snapshot.paramMap.get('id')}`;
      }
      route = route.firstChild as ActivatedRoute;
    }
    return title;
  }
}
