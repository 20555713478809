import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  private scrollSubject = new BehaviorSubject<boolean>(false);
  private scrollBottomSubject = new BehaviorSubject<boolean>(false);
  public isAtBottom = this.scrollBottomSubject.asObservable();
  public isSticky = this.scrollSubject.asObservable();

  constructor() { }

  updateSticky(isSticky: boolean) {
    this.scrollSubject.next(isSticky);
  }

  reachedBottom(reached: boolean) {
    this.scrollBottomSubject.next(reached);
  }
}
