import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class PwaService {

  constructor(private swUpdate: SwUpdate) { 
    if (this.swUpdate.isEnabled) {
      // Ascolta l'evento VersionReadyEvent
      this.swUpdate.versionUpdates.pipe(
        filter((event): event is VersionReadyEvent => event.type === 'VERSION_READY')
      ).subscribe(event => {
        if (confirm("Una nuova versione dell'app è disponibile. Caricare la nuova versione?")) {
          window.location.reload();
        }
      });
    }
  }
}
