<!-- =========== Navbar Start =========== -->
<header  #navbar id="navbar" class="@@link-color fixed top-0 inset-x-0 flex items-center z-40 w-full lg:bg-transparent bg-white transition-all py-5">
  <div class="container">
		<nav class="flex items-center">
				<!-- Navbar Brand Logo -->
				<a href="/">
						<img src="assets/images/logo.png" class="h-8" alt="My4steps">
				</a>

				<!-- Nevigation Menu -->
				<div class="hidden lg:block ms-auto">
					<ul class="navbar-nav flex gap-x-3 items-center justify-center">
						<!-- Home Page Link -->
						<li class="nav-item">
								<a class="nav-link" href="/">Home</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" href="/landing/page/come-installare-la-pwa">Come installare la PWA My4Steps</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" href="/landing/login">Login</a>
						</li>
						<li class="nav-item">
								<a class="nav-link" href="/landing/register">Registrati</a>
						</li>
					</ul>
				</div>
				<div class="hidden lg:flex items-center ms-3" *ngIf="doctorLink">
						<a [href]="doctorLink" target="_blank" class="bg-primary text-white px-4 py-2 rounded inline-flex items-center text-sm">Area medico</a>
				</div>

				<div class="lg:hidden flex items-center ms-auto px-2.5">
						<button type="button" 
						data-te-offcanvas-toggle
						data-te-target="#offcanvasRight"
						aria-controls="offcanvasRight"
						data-te-ripple-init
						data-te-ripple-color="light"
						>
								<fa-icon class="text-2xl text-gray-500" [icon]="['fas', 'bars']"></fa-icon>
						</button>
				</div>
		</nav>
  </div>
</header>

<div class="invisible fixed bottom-0 right-0 top-0 z-[1045] flex w-96 max-w-full translate-x-full flex-col border-none bg-white bg-clip-padding text-neutral-700 shadow-sm outline-none transition duration-300 ease-in-out data-[te-offcanvas-show]:transform-none dark:bg-body-dark dark:text-white"
tabindex="-1"
  id="offcanvasRight"
  aria-labelledby="offcanvasRightLabel"
  data-te-offcanvas-init
>
  <div class="flex flex-col h-full divide-y-2 divide-gray-200">
      <!-- Mobile Menu Topbar Logo (Header) -->
      <div class="p-6 flex items-center justify-between">
          <a href="/">
              <img src="assets/images/logo.png" class="h-8" alt="Logo">
          </a>
      </div>

      <!-- Mobile Menu Link List -->
      <div class="p-6 overflow-scroll h-full">
          <ul class="navbar-nav flex flex-col gap-2" data-fc-type="accordion">
              <!-- Home Page Link -->
              <li class="nav-item">
                  <a href="/" class="nav-link">Home</a>
              </li>
							<li class="nav-item">
								<a class="nav-link" href="/landing/page/come-installare-la-pwa">Come installare la PWA My4Steps</a>
							</li>
              <li class="nav-item">
                <a class="nav-link"  href="/doctor/login">Login</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/register">Registrati</a>
            </li>
						<li class="nav-item">
              <a [href]="doctorLink" target="_blank" class="nav-link" >Area medico</a>
            </li>
          </ul>
      </div>
  </div>
</div>

<section class="overflow-visible">
  <ion-router-outlet></ion-router-outlet>
</section>

<section class="relative py-10 bg-gray-100" [hidden]="!showFooter">
  <div class="container">
		<div class="grid xl:grid-cols-5 gap-6">
			<div class="xl:col-span-2">
				<p class="text-gray-500/80 mt-5 lg:w-4/5 text-sm">My4Steps è un servizio totalmente gratuito di Lundbeck Italia S.p.A. erogato da PHD Lifescience S.r.l., provider di healthcare services</p>
				<div class="flex gap-6 mt-4">
					<a href="https://phdlifescience.eu/" target="_blank" class="text-gray-500/80 hover:text-primary" title="PHD Lifescience"><img src="assets/images/logo-phd.png" class="h-10 mx-auto"></a>
					<a href="https://www.lundbeck.com/it" target="_blank" class="text-gray-500/80 hover:text-primary" title="Lundbeck"><img src="assets/images/logo-lundbeck.png" class="h-10 mx-auto"></a>
				</div>
			</div>
			<div class="xl:col-span-3 col-span-4">
				<div class="flex flex-col sm:flex-row gap-6 flex-wrap justify-between">
					<div>
						<div class="flex flex-col gap-3">
							<h5 class="mb-3 uppercase">My4steps</h5>
							<div class="text-gray-500/80"><a href="/landing/page/privacy" class="hover:text-primary me-4">Login</a></div>
							<div class="text-gray-500/80"><a href="/landing/page/termini-e-condizioni" class="hover:text-primary me-4">Registrati</a></div>
						</div>
					</div>
					<div>
						<div class="flex flex-col gap-3">
							<h5 class="mb-3 uppercase">Legal</h5>
							<div class="text-gray-500/80"><a href="/landing/page/privacy" class="hover:text-primary me-4">Privacy</a></div>
							<div class="text-gray-500/80"><a href="/landing/page/termini-e-condizioni" class="hover:text-primary me-4">Termini e condizioni</a></div>
							<div class="text-gray-500/80"><a href="/landing/cookie-policy" class="hover:text-primary me-4">Cookie policy</a></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="text-center">
			<p class="my-5 text-gray-500/80 text-sm">
				<script>document.write(new Date().getFullYear())</script> © My4Steps. 
			</p>
			<a href="/landing" title="Home">
				<img src="assets/images/logo.png" class="h-8 mx-auto">
			</a>
 		</div>
	</div>
</section>

