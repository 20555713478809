import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_helpers/auth.guard';
import { LandingComponent } from './landing/landing.component';
import { PwaComponent } from './pwa/pwa.component';

const routes: Routes = [
  {
    path: 'landing',
    component: LandingComponent,
    children: [
      { path: '', redirectTo: 'welcome', pathMatch: 'full' },
      { path: 'login', 
        data: { title: 'Login' },
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule) 
      },
      { 
        path: 'register', 
        data: { title: 'Registrazione' },
        loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule) 
      },
      { 
        path: 'welcome', 
        data: { title: 'Home landing' },
        loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomePageModule) 
      },
      { 
        path: 'verify-email',
        data: { title: 'Verifica email' },
        loadChildren: () => import('./pages/verify-email/verify-email.module').then( m => m.VerifyEmailPageModule)
      },
      {
        path: 'forgot-password',
        loadChildren: () => import('./pages/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
      },
      {
        path: 'reset-password',
        loadChildren: () => import('./pages/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
      },
      { 
        path: 'page/:slug', 
        data: { title: 'Pagina' },
        loadChildren: () => import('./pages/page/page.module').then( m => m.PagePageModule)
      },
      {
        path: 'cookie-policy',
        data: { title: 'Cookie policy' },
        loadChildren: () => import('./pages/cookie-policy/cookie-policy.module').then( m => m.CookiePolicyPageModule)
      },
    ]
  },
  {
    path: 'pwa',
    component: PwaComponent,
    children: [
      {
        path: 'habit',
        canActivate: [AuthGuard],  
        data: { title: 'Stile di vita' },
        loadChildren: () => import('./pages/lifestyle/habit/habit.module').then( m => m.HabitPageModule)
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],   
        data: { title: 'Dashboard' },     
        loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
      },
      {
        path: 'settings',
        canActivate: [AuthGuard],  
        data: { title: 'Impostazioni' },
        loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
      },
      {
        path: 'configure',
        canActivate: [AuthGuard],
        data: { title: 'Configurazione' },
        loadChildren: () => import('./pages/configure/configure.module').then( m => m.ConfigurePageModule)
      },
      {
        path: 'main',
        canActivate: [AuthGuard],  
        data: { title: 'Home' },
        loadChildren: () => import('./pages/main/main.module').then( m => m.MainPageModule)
      },
      {
        path: 'phe',
        canActivate: [AuthGuard],  
        data: { title: 'PHE' },
        loadChildren: () => import('./pages/phe/phe.module').then( m => m.PhePageModule)
      },
      {
        path: 'post-popup',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/post-popup/post-popup.module').then( m => m.PostPopupPageModule)
      },
      {
        path: 'migraine-log',
        canActivate: [AuthGuard],
        data: { title: 'Attacco di emicrania' },
        loadChildren: () => import('./pages/monitoring/migraine-log/migraine-log.module').then( m => m.MigraineLogPageModule)
      },
      {
        path: 'register-activity',
        canActivate: [AuthGuard],  
        data: { title: 'Registra attività' },
        loadChildren: () => import('./pages/register-activity/register-activity.module').then( m => m.RegisterActivityPageModule)
      },
      {
        path: 'profile',
        canActivate: [AuthGuard],  
        data: { title: 'Profilo' },
        loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
      },
      {
        path: 'survey',
        canActivate: [AuthGuard],  
        data: { title: 'Questionario' },
        loadChildren: () => import('./pages/survey/survey.module').then( m => m.SurveyPageModule)
      },
      {
        path: 'drug-selector',
        canActivate: [AuthGuard],  
        data: { title: 'Seleziona farmaco' },
        loadChildren: () => import('./pages/drug-selector/drug-selector.module').then( m => m.DrugSelectorPageModule)
      },
      {
        path: 'survey-result',
        canActivate: [AuthGuard],  
        data: { title: 'Risultato questionario' },
        loadChildren: () => import('./survey-result/survey-result.module').then( m => m.SurveyResultPageModule)
      },
      {
        path: 'tutorial',
        canActivate: [AuthGuard],  
        data: { title: 'Tutorial' },
        loadChildren: () => import('./pages/tutorial/tutorial.module').then( m => m.TutorialPageModule)
      },
    ]
  },
  { path: '', redirectTo: '/landing/welcome', pathMatch: 'full' }, // Default redirect
  { path: '**', redirectTo: '/landing/login' },   {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then( m => m.WelcomePageModule)
  },
  {
    path: 'popup-challenge',
    loadChildren: () => import('./pages/lifestyle/popup-challenge/popup-challenge.module').then( m => m.PopupChallengePageModule)
  },
// Wildcard route per gestire le rotte non trovate
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
