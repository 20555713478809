export const environment = {
  production: true,
  API_URL: 'https://panel.my4steps.it/api/v1',
  MEDICAL_URL: 'https://panel.my4steps.it',
  googleAnalytics: 'G-6ERQXPSF9F',

  firebase: {
    apiKey: 'AIzaSyB8m06ppJLHrRdZQj0B235x97UGMkHF1RA',
    authDomain:'my4steps-415016.firebaseapp.com',
    projectId: 'my4steps-415016',
    storageBucket: 'my4steps-415016.appspot.com',
    messagingSenderId: '859634051815',
    appId: '1:859634051815:web:9def861684bf8ce0101042',
    measurementId: 'G-DX0SZYZE5H',
    vapidKey: 'BFXICb2omNCOGeafCKB4IdfjItNgb6ByE_GQsGEjrXj4EfeVryNoOvoqjbGtLIIUX5RwOnFY-hWZWmVed9FwWsU'
  }
};
