import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { query } from 'kitsu-core';
import { deserialize } from 'jsonapi-deserializer';
import { JsonApiResponse } from '../_models/json-api-response';
import { QueryParameters } from '../_models/query-parameters';
import { ActivityChart } from '../_models/activity-chart';
import { User } from '../_models/user';
import { StorePatient } from '../_models/store-patient';

@Injectable({
  providedIn: 'root'
})
export class MeService {
  private counselingEnabled!: BehaviorSubject<boolean>;
  public hasCounseling!: Observable<boolean>;

  constructor(
    private http: HttpClient,
  ) { 
    this.counselingEnabled = new BehaviorSubject<boolean>(false);
    this.hasCounseling = this.counselingEnabled.asObservable();
  }

  get(queryParameters?: QueryParameters): Observable<User> {
    const q = queryParameters ? query(queryParameters) : '';
    return this.http.get<JsonApiResponse<User>>(environment.API_URL + '/me', 
      {
        params: new HttpParams({fromString: q})
      })
      .pipe(
        map((response: JsonApiResponse<User>) => {
          const output = deserialize(response);
          this.counselingEnabled.next(output.profile.counseling_enabled);
          return output;
        }),
        catchError((error: HttpErrorResponse) => {
          console.error(error);
          return throwError(() => 'Errore durante il recupero degli eventi. Si prega di riprovare più tardi.');
        })
      );
  }

  getMigraineFree(args?: Object): Observable<ActivityChart[]> {
    const q = query(args);
    return this.http.get<ActivityChart[]>(environment.API_URL + '/me/migraine-free-chart', 
      {
        params: new HttpParams({fromString: q})
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getPainLevel(args?: Object): Observable<ActivityChart[]> {
    const q = query(args);
    return this.http.get<ActivityChart[]>(environment.API_URL + '/me/pain-level-chart', 
      {
        params: new HttpParams({fromString: q})
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getFrequency(args?: Object): Observable<ActivityChart[]> {
    const q = query(args);
    return this.http.get<ActivityChart[]>(environment.API_URL + '/me/frequency-chart', 
      {
        params: new HttpParams({fromString: q})
      })
      .pipe(
        map((response: any) => {
          const output = deserialize(response);
          return output;
        })
      );
  }

  updateProfile(user: StorePatient) {
    return this.http.put(`${environment.API_URL}/me/profile`, user);
  }

  updatePassword(form: any) {
    return this.http.put(`${environment.API_URL}/me/password`, form);
  }

  changeDoctor(user: StorePatient) {
    return this.http.put(`${environment.API_URL}/me/doctor`, user);
  }

  isCounselingEnabled(): boolean {
    return this.counselingEnabled.value;
  }

  setCounselingEnabled(counselingEnabled: boolean) {
    this.counselingEnabled.next(counselingEnabled);
  }

  refreshCounselingEnabled(): Observable<User> {
    const meArgs = {
      include: 'profile',
    }

    const q = query(meArgs);
    return this.http.get<JsonApiResponse<User>>(environment.API_URL + '/me',
    {
      params: new HttpParams({fromString: q})
    })
    .pipe(
      map((response: JsonApiResponse<User>) => {
        const output = deserialize(response);
        this.counselingEnabled.next(output.profile?.counseling_enabled);
        return output;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => 'Errore durante il recupero del profilo. Si prega di riprovare più tardi.');
      })
    );
  }

  revoke(queryParameters?: QueryParameters): Observable<User> {
    const q = queryParameters ? query(queryParameters) : '';
    return this.http.put<JsonApiResponse<User>>(environment.API_URL + '/me/revoke',
      {
      params: new HttpParams({fromString: q})
      })
      .pipe(
        map((response: JsonApiResponse<User>) => {
          return deserialize(response);
        }),
        catchError((error: HttpErrorResponse) => {
          console.error(error);
          return throwError(() => 'Errore durante la revoca del token. Si prega di riprovare più tardi.');
        })
      );
  }
}
