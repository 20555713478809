import { Component, OnInit, ElementRef, HostListener, ViewChild, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ScrollService } from '../_services/scroll.service';
import * as AOS from 'aos';
import { environment } from 'src/environments/environment';
import { Offcanvas, Ripple, initTE } from 'tw-elements';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent  implements OnInit, OnDestroy {
  @ViewChild('navbar') navbar!: ElementRef;

  private scrollSub!: Subscription;
  public isSticky: boolean = false;
  protected showFooter: boolean = false;
  protected doctorLink!: string;


  constructor(
    private scrollService: ScrollService
  ) { 
   
  }

  ngOnInit() {
    AOS.init();
    initTE({ Offcanvas, Ripple });
    this.doctorLink = environment.MEDICAL_URL;

    this.scrollSub = this.scrollService.isSticky.subscribe(isSticky => {
      const element = this.navbar ? this.navbar.nativeElement : null;
      this.isSticky = isSticky;
      if(element) {
        if (isSticky) {
          element.classList.add('nav-sticky');
        } else {
          element.classList.remove('nav-sticky');
        }
      }
    });

    const bottomSub = this.scrollService.isAtBottom.subscribe(reached => {
      if (reached) {
        this.showFooter = true;
      } else {
        this.showFooter = false;
      }
    });

    this.scrollSub.add(bottomSub);
  }

  ngOnDestroy(): void {
    if (this.scrollSub) {
      this.scrollSub.unsubscribe();
    }
  }
}
