import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Storage } from '@ionic/storage-angular';
import { map } from 'rxjs/operators';
import { LoggedUser } from '../_models/logged-user';
import { environment } from '../../environments/environment';
import { en } from '@fullcalendar/core/internal-common';
import { StorePassword } from '../_models/store-password';


@Injectable({
  providedIn: 'root'
})
export class AccountService {
  public initDone: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private userSubject: BehaviorSubject<LoggedUser | null | undefined>;
  private _storage: Storage | null = null;
  public user: Observable<LoggedUser | null | undefined>;

  constructor(
    private http: HttpClient,
    private storage: Storage,
    private router: Router
  ) 
  { 
    this.userSubject = new BehaviorSubject<LoggedUser | null | undefined>(null);
      this.user = this.userSubject.asObservable();
      this.init();
  }

  public get userValue() {
    return this.userSubject;
  }

  login(email: string, password: string) {
    return this.http.post<LoggedUser>(`${environment.API_URL}/login`, { email, password })
      .pipe(map((user) => {
          this.storage.set("user", user);
          this.userSubject.next(user);
          return user;
      }));
  }

  email(email: string) {
    return this.http.post(`${environment.API_URL}/password/email`, {email});
  }

  reset(passwordReset: StorePassword) {
    return this.http.post(`${environment.API_URL}/password/reset`, passwordReset);
  }

  async logout() {
    this.userSubject.next(null);
    this.storage?.remove("user");
    this.router.navigate(['/login']);
  }

  async init() {
    const storage = await this.storage.create();
    this._storage = storage;
    const loggedUser = await this._storage.get("user");
    if(loggedUser) {
      this.userSubject.next(loggedUser);
    } else {
      this.userSubject.next(null);
    }
    this.initDone.next(true);
  }

  verifyEmail(url: string) {
    return this.http.get(url, {});
  }
}
