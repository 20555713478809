import { Component } from '@angular/core';
import { MeService } from './_services/me.service';
import { register } from 'swiper/element/bundle';
import { Capacitor } from '@capacitor/core';
import { environment } from "src/environments/environment";
import { initializeApp } from "firebase/app";
import { GoogleAnalyticsService } from './_services/google-analytics.service';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Home', url: '/pwa/main', icon: 'person' },
    { title: 'Profilo', url: '/pwa/profile', icon: 'person' },
    { title: 'Settings', url: '/pwa/settings', icon: 'warning' },
    { title: 'Tutorial', url: '/pwa/tutorial', icon: 'warning' },
  ];

  public isLoginPageOrRegisterPage: boolean = false;
  
  constructor(
    private meService: MeService,
    private gaService: GoogleAnalyticsService
  ) 
  {
    this.initializeFirebase();
  }

  public async initializeFirebase(): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      return;
    }
    initializeApp(environment.firebase);
  }

  ngOnInit() {
   this.gaService.init();
  }

  ngOnDestroy(): void {
   
  }
}
