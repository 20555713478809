import { Component, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { AccountService } from '../_services/account.service';

@Component({
  selector: 'app-pwa',
  templateUrl: './pwa.component.html',
  styleUrls: ['./pwa.component.scss'],
})
export class PwaComponent  implements OnInit {
  public appPages = [
    { title: 'Home', url: '/pwa/main', icon: 'person' },
    { title: 'Profilo', url: '/pwa/profile', icon: 'person' },
    { title: 'Impostazioni', url: '/pwa/settings', icon: 'warning' },
    { title: 'Tutorial', url: '/pwa/tutorial', icon: 'warning' },
  ];

  constructor(
    private menuCtrl: MenuController,
    private accountService: AccountService,
  ) { }

  ngOnInit() {}

  logout() {
    this.menuCtrl.enable(true);
    this.accountService.logout();
  }

}
